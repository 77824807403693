import { CarouselContentElementProps } from "./carouselContentElement";

export interface CarouselContentElementStyleScopedProps
  extends CarouselContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 *
 * @param props
 * @returns
 */
export const CarouselContentElementStyleScoped = (
  props: CarouselContentElementStyleScopedProps
) => {
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        width: ${props.ceSettings?.width ?? "100%"};

        .text {
          display: block;
        }
        .icon {
          display: none;
        }
        &:hover {
          .text {
            display: none;
          }
          .icon {
            display: block;
          }
        }
      }
    `}</style>
  );
};
