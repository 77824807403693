import CmsAddButton from "@/components/cms/cmsAddButton/cmsAddButton";
import ArrowControls from "@/components/util/arrowControls/arrowControls";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useScopedClassName from "@/hooks/useScopedClassName";
import { valueFromStoreSetting } from "@/services/ceSettings/ceSettingsService";
import { showNestedContentModal } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementCarouselStoreSetting } from "@/types/ceSettings/ceSettings";
import { CECarousel, CECarouselSlide } from "@/types/content-elements";
import { getIdOrNewId } from "@/utils/util";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import BSCarousel from "react-bootstrap/Carousel";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import CarouselContentElementStyleGlobal from "./carouselContentElementStyleGlobal";
import { CarouselContentElementStyleScoped } from "./carouselContentElementStyleScoped";
import CarouselItem, { CAROUSEL_ITEM_NAME } from "./carouselItem/carouselItem";

export interface CarouselContentElementProps {
  content: CECarousel;
  position: number;
  ceSettings?: ContentElementCarouselStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const CAROUSEL_CE_NAME = "carouselCE";

export default React.memo(function CarouselContentElement(
  props: CarouselContentElementProps
) {
  const CAROUSEL_SCOPED_CLASS = useScopedClassName(
    CAROUSEL_CE_NAME,
    props.content,
    props.position
  );
  const dispatch = useAppDispatch();
  const [isCarouselMoving, setIsCarouselMoving] = useState<boolean>(true);
  const carouselRef = useRef<any>(null);
  const tCms = useCmsTranslation();

  const slides = props.content.content.filter(
    (slide) =>
      slide.img ||
      slide.title ||
      slide.richTextContent.trim().length > 0 ||
      slide.secondHeadline ||
      (slide.linkText && slide.linkUrl)
  );

  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const arrowsId = props.ceSettings
    ? valueFromStoreSetting(props.ceSettings?.arrows)
    : null;

  return (
    <>
      <ContentElementWrapper
        name={CAROUSEL_CE_NAME}
        scopedClassName={CAROUSEL_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div
          className={clsx(
            CAROUSEL_CE_NAME,
            CAROUSEL_SCOPED_CLASS,
            "position-relative"
          )}
        >
          {slides.length >= 1 ? (
            <>
              <ArrowControls
                hideArrows={slides.length < 2}
                arrowsId={arrowsId ?? undefined}
                onNextClick={() => carouselRef.current.next()}
                onPrevClick={() => carouselRef.current.prev()}
              />
              <BSCarousel
                ref={carouselRef}
                controls={false}
                interval={
                  isCarouselMoving && props.ceSettings?.intervalMS
                    ? props.ceSettings.intervalMS
                    : null
                }
                onFocus={() => setIsCarouselMoving(false)}
                onBlur={() => setIsCarouselMoving(true)}
              >
                {slides.map((slide: CECarouselSlide, index: number) => {
                  const CarouselItemScopedClassName =
                    CAROUSEL_ITEM_NAME +
                    "-scoped-" +
                    getIdOrNewId(slide, index);

                  return (
                    <BSCarousel.Item
                      key={index}
                      className={CarouselItemScopedClassName}
                    >
                      <CarouselItem
                        nestedPosition={index}
                        slide={slide}
                        content={props.content}
                        ceSettings={props.ceSettings!}
                        isMobile={props.isMobile}
                        scopedClassName={CarouselItemScopedClassName}
                      />
                    </BSCarousel.Item>
                  );
                })}
              </BSCarousel>
            </>
          ) : (
            <>
              {editView && (
                <div style={{ marginLeft: "4rem", marginRight: "4rem" }}>
                  <CmsAddButton
                    title={tCms("addNewSlide")}
                    text={tCms("addNewSlide")}
                    onClick={() => {
                      dispatch(showNestedContentModal(props.position));
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </ContentElementWrapper>
      <CarouselContentElementStyleGlobal {...props} />
      <CarouselContentElementStyleScoped
        {...props}
        scopedSelector={CAROUSEL_SCOPED_CLASS}
      />
    </>
  );
});
