import PbContentButton from "@/components/content/pbContentButton/pbContentButton";
import PbContentHeading from "@/components/content/pbContentHeading/pbContentHeading";
import PbContentImage from "@/components/content/pbContentImage/pbContentImage";
import PbContentRichText from "@/components/content/pbContentRichText/pbContentRichText";
import useCesStrButton from "@/hooks/useCesStrButton";
import useCesStrHeadline from "@/hooks/useCesStrHeadline";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import { getNestedCeSettingsData } from "@/services/ceSettings/ceSettingsService";
import { ContentElementCarouselStoreSetting } from "@/types/ceSettings/ceSettings";
import { HeadlineHeading } from "@/types/config/enums";
import { CECarousel, CECarouselSlide } from "@/types/content-elements";
import { getPbContentScopedSelector } from "@/utils/util";
import clsx from "clsx";
import { useRef } from "react";
import BSCarousel from "react-bootstrap/Carousel";
import CarouselItemStyleScoped from "./carouselItemStyleScoped";

export interface CarouselItemProps {
  slide: CECarouselSlide;
  content: CECarousel;
  ceSettings: ContentElementCarouselStoreSetting;
  isMobile: boolean;
  scopedClassName: string;
  nestedPosition: number;
}

export const CAROUSEL_ITEM_NAME = "carousel-item";

const CarouselItem = (props: CarouselItemProps) => {
  const headlineSettings = useCesStrHeadline({
    typography: props.content.cfgStrInnerHeadlineTypography,
    font: props.content.cfgStrInnerHeadlineFont,
    fontColor: props.content.cfgStrInnerHeadlineFontColor,
    cfgHeadlineType: props.content.cfgHeadlineType as HeadlineHeading,
    ceSettingHeadline: props.ceSettings?.headlineheading,
    ceSettings: getNestedCeSettingsData(props.ceSettings?.headline),
    additionalClasses: clsx(
      !props.content.cfgShowTitleInMobile && "hide-in-mobile",
      "title"
    ),
  });
  const subheadlineSettings = useCesStrHeadline({
    typography: props.content.cfgStrInnerSubheadlineTypography,
    font: props.content.cfgStrInnerSubheadlineFont,
    fontColor: props.content.cfgStrInnerSubheadlineFontColor,
    cfgHeadlineType: props.content.cfgSubheadlineType as HeadlineHeading,
    ceSettingHeadline: props.ceSettings?.subheadlineheading,
    ceSettings: getNestedCeSettingsData(props.ceSettings?.subheadline),
    additionalClasses: clsx(
      !props.content.cfgShowHeadlineInMobile && "hide-in-mobile",
      "teaser"
    ),
  });

  const { richTextClassName, richtextEditorSettings } = useCesStrRichText(
    props.ceSettings?.richtext,
    props.ceSettings?.richtext
  );
  const { buttonClassName } = useCesStrButton(
    props.content.cfgStrInnerButtonButton,
    props.ceSettings?.button
  );

  const captionRef = useRef<HTMLDivElement>(null);
  const titleWrapperRef = useRef<HTMLDivElement>(null);
  const teaserWrapperRef = useRef<HTMLDivElement>(null);
  const richtextWrapperRef = useRef<HTMLDivElement>(null);
  const buttonWrapperRef = useRef<HTMLDivElement>(null);

  const height = {
    mobile: props.ceSettings?.height.mobile,
    tablet: props.ceSettings?.height.tablet,
    desktop: props.ceSettings?.height.desktop,
    wqhd: props.ceSettings?.height.wqhd,
  };

  const hasShadowLayerBottom = () => {
    if (props.content.cfgShadowLayerBottom === "with-layer") {
      return true;
    } else if (
      props.ceSettings?.shadowLayerBottom &&
      (!props.content.cfgShadowLayerBottom ||
        props.content.cfgShadowLayerBottom === "default")
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="carousel-image-container">
        {props.slide.img && (
          <PbContentImage
            width={"100%"}
            height={"100%"}
            responsive={height}
            img={props.slide.img}
            objectFit="cover"
            isMobile={props.isMobile}
            disableEditView={true}
            skipOptimization={props.content.cfgUseHighestImageQuality}
            widthLimitSettings={
              props.ceSettings?.width && props.ceSettings?.width.includes("px")
                ? Number(props.ceSettings?.width.replace("px", ""))
                : undefined
            }
          />
        )}
        {hasShadowLayerBottom() ? <div className="image-shadow"></div> : null}
      </div>
      {(props.slide.title ||
        props.slide.secondHeadline ||
        props.slide.richTextContent ||
        (props.slide.linkUrl && props.slide.linkText)) && (
        <BSCarousel.Caption
          ref={captionRef}
          className={`${
            !props.content.cfgShowTitleInMobile &&
            !props.content.cfgShowHeadlineInMobile &&
            !props.content.cfgShowTextInMobile &&
            !props.content.cfgShowButtonInMobile
              ? "hide-in-mobile"
              : ""
          }`}
        >
          {props.slide.title && (
            <div ref={titleWrapperRef}>
              <PbContentHeading
                disableEditView
                content={props.slide.title}
                scopedSelector={getPbContentScopedSelector(
                  props.scopedClassName,
                  "headline",
                  props.nestedPosition
                )}
                settings={{
                  ...headlineSettings,
                }}
              />
            </div>
          )}
          {props.slide.secondHeadline && (
            <div ref={teaserWrapperRef}>
              <PbContentHeading
                disableEditView
                content={props.slide.secondHeadline}
                scopedSelector={getPbContentScopedSelector(
                  props.scopedClassName,
                  "subheadline",
                  props.nestedPosition
                )}
                settings={{
                  ...subheadlineSettings,
                }}
              />
            </div>
          )}
          {props.slide.richTextContent && (
            <div ref={richtextWrapperRef}>
              <PbContentRichText
                className={clsx(
                  richTextClassName,
                  !props.content.cfgShowTextInMobile && "hide-in-mobile"
                )}
                content={props.slide.richTextContent}
                disableEditView
                ceSettings={richtextEditorSettings}
              />
            </div>
          )}
          {props.slide.linkText && props.slide.linkUrl && (
            <div className="button-wrapper" ref={buttonWrapperRef}>
              <PbContentButton
                url={props.slide.linkUrl}
                text={props.slide.linkText}
                disableEditView
                getParams={props.slide?.linkGetParams}
                hrefLang={props.slide?.linkHrefLang}
                rel={props.slide?.linkRel}
                target={props.slide.linkTarget}
                anchorName={props.slide.linkAnchor}
                className={clsx(
                  buttonClassName,
                  !props.content.cfgShowButtonInMobile && "hide-in-mobile"
                )}
              />
            </div>
          )}
        </BSCarousel.Caption>
      )}
      <CarouselItemStyleScoped
        {...props}
        imageMinHeight={height}
        scopedSelector={props.scopedClassName}
      />
    </>
  );
};
export default CarouselItem;
