import { useTranslation } from "next-i18next";
import { ArrowProps } from "../arrowControls/arrowControls";

export interface LeftArrowProps extends ArrowProps {}

export default function LeftArrow(props: LeftArrowProps) {
  const { t: tPublic } = useTranslation("public");
  return (
    <button
      type="button"
      className={`left-arrow-button arrow left-arrow`}
      disabled={props.disabled}
      onClick={props.onClick}
      aria-label={tPublic("back")}
    ></button>
  );
}
