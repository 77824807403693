export default function ArrowControlsStyle() {
  return (
    <style jsx global>
      {`
        .arrow-container {
          height: 100%;
          z-index: 1;
          align-items: center;
          position: relative;

          &:hover {
            :global(button) {
              opacity: 0.8;
            }
          }

          &.on-top-of-content {
            position: absolute;
          }
          &.left-arrow-container {
            &.on-top-of-content {
              left: 0;
            }
            &.fade {
              background: rgb(255, 255, 255);
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 41%,
                rgba(255, 255, 255, 0.33) 73%,
                rgba(255, 255, 255, 0) 95%
              );
            }
          }
          &.right-arrow-container {
            &.fade {
              background: rgb(255, 255, 255);
              background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 41%,
                rgba(255, 255, 255, 0.33) 73%,
                rgba(255, 255, 255, 0) 95%
              );
            }
            &.on-top-of-content {
              left: 100%;
              transform: translateX(-100%);
            }
          }
        }
      `}
    </style>
  );
}
