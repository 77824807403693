import { STORE_ARROW_CLASS_PREFIX } from "@/utils/constants";
import clsx from "clsx";
import { ReactNode } from "react";
import LeftArrow from "../arrows/leftArrow";
import RightArrow from "../arrows/rightArrow";
import ArrowControlsStyle from "./arrowControlsStyle";

export interface ArrowControlsProps {
  hideArrows?: boolean;
  onNextClick: Function;
  onPrevClick: Function;
  children?: ReactNode;
  fade?: boolean;
  showRightArrow?: boolean;
  showLeftArrow?: boolean;
  classNameLeft?: string;
  classNameRight?: string;
  /**
   * the strapi arrow store id. If not given a fallback arrow is shown.
   */
  arrowsId?: number;
}

export interface ArrowProps {
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const ArrowControls = (props: ArrowControlsProps) => {
  const {
    hideArrows,
    onNextClick,
    onPrevClick,
    children,
    fade,
    showRightArrow,
    showLeftArrow,
    classNameLeft,
    classNameRight,
    arrowsId,
  } = props;

  return (
    <>
      {(!hideArrows || showLeftArrow) && (
        <div
          className={clsx(
            "arrow-container left-arrow-container",
            !children && "on-top-of-content",
            fade && "fade",
            classNameLeft,
            `${STORE_ARROW_CLASS_PREFIX}${arrowsId}`
          )}
        >
          <LeftArrow
            onClick={() => {
              if (onPrevClick) {
                onPrevClick();
              }
            }}
          />
        </div>
      )}
      {children}
      {(!hideArrows || showRightArrow) && (
        <div
          className={clsx(
            "arrow-container right-arrow-container",
            !children && "on-top-of-content",
            fade && "fade",
            classNameRight,
            `${STORE_ARROW_CLASS_PREFIX}${arrowsId}`
          )}
        >
          <RightArrow
            onClick={() => {
              if (onNextClick) {
                onNextClick();
              }
            }}
          />
        </div>
      )}
      <ArrowControlsStyle />
    </>
  );
};

export default ArrowControls;
