import { lowerBreakpointDesktopPx, lowerBreakpointTabletPx } from "@/utils/util";
import { CAROUSEL_CE_NAME, CarouselContentElementProps } from "./carouselContentElement";

/**
 * This is the global style of a CE. 
 * This style is applied on all instances of this CE
 * 
 * @param props 
 * @returns 
 */
export default function CarouselContentElementStyleGlobal(props: CarouselContentElementProps) {
  return (
    <style jsx global>
      {`
        :global(.${CAROUSEL_CE_NAME}) {
          margin: auto;
          max-width: 100%;
          position: static;

          :global(.relative) {
            position: relative;
          }

          .carousel.slide {
            .carousel-indicators {
              button {
                display: none;
              }
            }
            .carousel-control-prev,
            .carousel-control-next {
              .carousel-control-next-icon,
              .carousel-control-prev-icon {
                width: 4rem;
                height: 4rem;
              }
            }
          }

          @media (min-width: ${lowerBreakpointTabletPx()}) {
            .carousel.slide {
              .carousel-control-prev,
              .carousel-control-next {
                display: flex;
                opacity: 1;
              }
            }
          }

          .one-slide {
            .carousel-control-prev,
            .carousel-control-next,
            .carousel-indicators {
              visibility: hidden;
            }
          }

          .default-arrow {
            font-size: ${14}px;
          }
          @media (min-width: ${lowerBreakpointTabletPx()}) {
            .default-arrow {
              font-size: ${16}px !important;
            }
          }
          @media (min-width: ${lowerBreakpointDesktopPx()}) {
            .default-arrow {
              font-size: ${18}px !important;
            }
          }
        }
        .carousel-placeholder {
          width: 100%;
          text-align: center;
          padding: 1.5rem 0;
        }

        /* 1.1: caption height defines maximum height of content. preventing layout breaks.*/
        .carousel-caption {
          overflow: hidden;
        }
      `}
    </style>
  );
}
